import AWS from 'aws-sdk'
import './CakeGallery.css'
import { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout'

export default function CakeGallery() {
  const [cakePics, setCakePics] = useState([])

  useEffect(() => {
    const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_REGION
    });

    async function listObject() {
    const listParams = {
      Bucket: 'cakes-by-mayra',
      Prefix: 'cakes-pictures',
    }
      const listedObjects = await s3.listObjectsV2(listParams).promise()
      listedObjects.Contents.shift()
      setCakePics(listedObjects.Contents)
    }
    listObject()

  }, [])
 
  return (
    <Layout>
      <div className='img-container'>
        <span className='gallery-span'>Cake Gallery</span>
    <div className='img-flex-container'>
      {cakePics.map((pic) => (
        <img src={`${process.env.REACT_APP_CAKES_PREFIX}/${pic.Key.slice(15)}`} alt='cake' className='img-pics'key={pic.Key}/>
      ))}
        </div>
    </div>
    </Layout>
  )
}
