import "./CakeForm.css"
import { useRef } from 'react';
import { useState } from "react"
import { createCakeClient } from "../../services/clients";
import { GoFileSubmodule } from "react-icons/go";
import { TailSpin } from "react-loader-spinner";
import Layout from "../../components/Layout/Layout"
import randomstring from "randomstring";
import AWS from 'aws-sdk'

export default function CakeForm() {
  const inputRef = useRef(null)
    const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_REGION
    });
  const [loading, setLoading] = useState(false);
  const [formMsg, setFormMsg] = useState("")
  const [inquiry, setInquiry] = useState({
    name: '',
    email: '',
    phone_number: '',
    servings: '',
    date_needed: '',
    cake_finish: '',
    delivery_method: '',
    address: '',
    s3: ''
  })
  const [files, setFiles] = useState([])
  const [submitMsg, setSubmitMsg] = useState({})
  const [submitBtn, setSubmitBtn] = useState({})
  const [errorMsg, setErrorMsg] = useState({})
  const handleChange = (e) => {
    const attri = document.querySelector('.address-text')
    if (e.target.value === "Pick Up") {
      attri.removeAttribute("required")
      const text = inputRef.current
      text.value = ''
    }
    if (e.target.value === "Delivery") {
      attri.setAttribute("required", true)
    }
    setInquiry({
      ...inquiry,
      [e.target.name] : e.target.value
    })
  }

  const handleFile = (e) => {
    const allFiles = [].concat(...e.target.files)
    setFiles([...files, ...allFiles])
  }

  const handleSubmit = async(e) => {
    e.preventDefault()
    const fileName = []
    try {
      setLoading(true);
      files.forEach(async (file) => {
        let today = new Date();
        let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        let dateTime = date + "-" + time;
        let randomStr = randomstring.generate(20);
        fileName.push(`${inquiry.email}/${randomStr}/${dateTime}`)
        const params = {
          Bucket: 'cakes-by-mayra-clients/cakes',
          Key: `${inquiry.email}/${randomStr}/${dateTime}`,
          Body: file
        };
        await s3.upload(params, function (error, data) {
          if (error) {
            throw error
          }
        }).promise()
      })
      const userData = {
        name: inquiry.name,
        email: inquiry.email,
        phone_number: inquiry.phone_number,
        servings: inquiry.servings,
        date_needed: inquiry.date_needed,
        cake_finish: inquiry.cake_finish,
        delivery_method: inquiry.delivery_method,
        address: inquiry.address,
        s3: fileName
      }
      await createCakeClient(userData)
      
      setInquiry({
        name: '',
        email: '',
        phone_number: '',
        servings: '',
        date_needed: '',
        cake_finish: '',
        delivery_method: '',
        address: '',
        s3: '',
      })
      setFiles([])
      const radioInput = document.getElementsByTagName('input')
      for (let i = 0; i < radioInput.length; i++) {
        if (radioInput[i].attributes[0].nodeValue === "radio") {
          radioInput[i].checked = false
        }
      }
      setFormMsg('*Inquiry Submitted. Please allow 24-48 hours for responce.*')
      setLoading(false);
      setSubmitBtn({ display: 'none' })
      setSubmitMsg({ display: 'flex' })
    } catch (error) {
      setInquiry({
        name: '',
        email: '',
        phone_number: '',
        servings: '',
        date_needed: '',
        cake_finish: '',
        delivery_method: '',
        address: '',
        s3: '',
      })
      setFiles([])
      const radioInput = document.getElementsByTagName('input')
      for (let i = 0; i < radioInput.length; i++) {
        if (radioInput[i].attributes[0].nodeValue === "radio") {
          radioInput[i].checked = false
        }
      }
      setLoading(false);
      setSubmitBtn({ display: 'none' })
      setFormMsg('*Servers temporary down. Please try again later.*')
      setErrorMsg({ display: 'flex' })
    }
  }
  return (
    <Layout>
        <form action="POST" className="form" onSubmit={handleSubmit}>
              <h1>CAKE ORDER INQUIRY FORM</h1><span>
                Thank you for your interest! Please review all information provided below and feel free to contact Mayra directly through email cakesbymayra@gmail.com or through direct message @ 6827011376 if you have any questions or concerns.
              </span><span>After submitting your inquiry please allow 24 to 48 hours for a follow up. </span><span>All orders must be picked up in Arlington, Texas 76010. Delivery may be available, depending on availability.</span><span>A 25% deposit is required to place your order. The remaining amount is due at the time of pick up. Deposit is non refundable in case of cancellation.</span><div className="user-data">
                  <label htmlFor="name">Name*</label>
                  <input type="text" className="text" id="name" value={inquiry.name} name="name" required onChange={handleChange} />
                  <label htmlFor="email">Email*</label>
                  <input type="email" className="text" id="email" required value={inquiry.email} name="email" onChange={handleChange} />
                  <label htmlFor="number">Phone Number*</label>
                  <input type="text" className="text" id="number" required value={inquiry.phone_number} name="phone_number" onChange={handleChange} />
                  <label htmlFor="servings">Amount of servings*</label>
                  <input type="number" id="servings" name="servings" min="1" max="300" value={inquiry.servings} required onChange={handleChange}></input>
                  <label htmlFor="date">Date needed*</label>
                  <input type="date" id="date" required name="date_needed" value={inquiry.date_needed} onChange={handleChange} />
                  <fieldset>
                    <legend>Cake finish*</legend>
                    <div>
                      <input type="radio" id="butter-cream" name="cake_finish" value="Butter Cream" onChange={handleChange} required />
                      <label htmlFor="butter-cream">Butter Cream</label>
                    </div>
                    <div>
                      <input type="radio" id="whipe-cream" name="cake_finish" value="Whipped Cream" onChange={handleChange} required />
                      <label htmlFor="whipe-cream">Whipped Cream</label>
                    </div>
                    <div>
                      <input type="radio" id="fondant" name="cake_finish" value="Fondant" onChange={handleChange} required />
                      <label htmlFor="fondant">Fondant</label>
                    </div>
                    <div>
                      <input type="radio" id="unsure" name="cake_finish" value="Unsure/Multiple" onChange={handleChange} required />
                      <label htmlFor="unsure">Unsure/Multiple</label>
                    </div>
                  </fieldset>
                  <fieldset>
                    <legend>Will you be picking up your order or would you like delivery?*</legend>
                    <div>
                      <input type="radio" id="pickup" name="delivery_method" value="Pick Up" onChange={handleChange} required />
                      <label htmlFor="pickup">Pick Up</label>
                    </div>
                    <div>
                      <input type="radio" id="delivery" name="delivery_method" value="Delivery" onChange={handleChange} required />
                      <label htmlFor="delivery">Delivery</label>
                      <input type="text" placeholder="address" name="address" value={inquiry.address} ref={inputRef} onChange={handleChange} className="address-text" id="address-input" />
                    </div>
                  </fieldset>
                  <div className="files">
                    <p className="file-text"> Design* (please include pictures for reference)</p>
                    <div className="file-flexdiv">
                      <label htmlFor="file"><GoFileSubmodule className="file-label" /></label>
                      <div className="file-list">
                        {files.map(file => (
                          <ul>
                            <li>{file.name}</li>
                          </ul>
                        ))}
                      </div>
                      <input type="file" multiple name="files" accept="image/*" onChange={handleFile} id="file"/>
                    </div>
            <small>If your picture file sizes are too big, email the pictures to cakesbymayra@gmail.com</small>
            </div>
            {
              loading ? (
                <div className="spinner-div">
                  <TailSpin color="red" radius={"8px"} width={"70px"}/>
                </div>
            ) :
              <>
                <div className="submit-btn" style={submitBtn}>
                <button className="btn">Submit</button>
              </div>
                <div className="check-mark" style={submitMsg}>
                  <div><i class="fa fa-check-circle"></i></div>
                  <div className="form-msg">
                    <p className="form-success-msg">Thanks for submitting the form!</p>
                    <p className="form-success-resp">{formMsg}</p>
                  </div>
                </div>
                <div style={errorMsg} className="error-msg"><p>{formMsg}</p></div>
              </>
          }
              </div>
          </form>
  </Layout>
  )
}
