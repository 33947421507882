import "./Home.css"
import { useEffect, useState } from 'react';
import AWS from 'aws-sdk'
import Layout from "../../components/Layout/Layout"


export default function Home() {
  const [pictures, setPictures] = useState([])

    useEffect(() => {
    const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_REGION
    });

    async function listObject() {
    const listParams = {
      Bucket: 'cakes-by-mayra',
      Prefix: 'home-pictures',
    }
      const listedObjects = await s3.listObjectsV2(listParams).promise()
      listedObjects.Contents.shift()
      setPictures(listedObjects.Contents)
    }
    listObject()

  }, [])
  
  return (
    <Layout>
      <div className="home-container">
        <img src={`${process.env.REACT_APP_LOGO}`} className="logo" alt="logo" />
        <span className="greetings-span">
          Thanks for visiting Cakes by Mayra, home bakery located in Arlington, TX where you can order custom cookies and cakes.
        </span>
          <hr />
          <div className='home-pics-div'>
      {pictures.map((pic) => (
        <img src={`${process.env.REACT_APP_HOME_PREFIX}/${pic.Key}`} alt='cookie' className='img-pics' key={pic.Key}/>
      ))}
      </div>
      </div>
    </Layout>
  )
}

